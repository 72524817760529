import React, { useState } from 'react';
import axios from 'axios';
import Input from '../../components/input/Input';
import './Login.scss';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [page, setPage] = useState('login');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://api.escapefromhunter.com/login', {
                username: username,
                password: password,
            });
            console.log(response)
            if (response.status == 200) {
                setPassword('');
                setPage('discord');
                // localStorage.setItem('token', response.data.token);
                // navigate('/');
            } else {
                alert('Login failed: ' + response.data.message);
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred during login.');
        }
    };

    const handleDiscord = async () => {
        try {
            const response = await axios.post('https://api.escapefromhunter.com/discord', {
                username: username,
                code: password,
            });
            console.log(response)
            if (response.status == 200) {
                localStorage.setItem('token', response.data.token);
                navigate('/');
            } else {
                setPage('login');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred during login.');
        }
    };

    return (
        <div className='login'>
            <div className='login-form'>
                {
                    page === 'login' ? 
                    <>
                        <h1>Login</h1>
                        <label>Username</label>
                        <Input 
                            placeholder={"Enter your username"} 
                            value={username}
                            setValue={(e) => setUsername(e)} 
                        />
                        <label>Password</label>
                        <Input 
                            placeholder={"Enter your password"} 
                            type="password"
                            value={password}
                            setValue={(e) => setPassword(e)} 
                        />
                        <div className='login-button' onClick={handleLogin}>Login</div>
                    </> :
                    <>
                        <h1>A2F</h1>
                        <label>Discord Code</label>
                        <Input 
                            placeholder={"Enter Discord A2F Code"} 
                            value={password}
                            setValue={(e) => setPassword(e)} 
                        />
                        <div className='login-button' onClick={handleDiscord}>Login</div>
                    </>
                }
                
            </div>
        </div>
    );
};

export default Login;
