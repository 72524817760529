import React from "react";
import "./Input.scss"
import {FunctionComponent, useState} from "react";


const Input = ({placeholder, value, setValue, type}) => {
    const [isActive, setIsActive] = useState(false);

    
    return (
        <div className={`input ${isActive ? 'focused' : ''}`}>
            <input type={type ? type : "text"} className={"input-comp"} placeholder={placeholder} onFocus={() => setIsActive(true)} onBlur={() => setIsActive(false)} value={value} onChange={e => setValue ? setValue(e.target.value) : console.log(e)} />
        </div>
    )
}

export default Input