
import './Server.scss'
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Navbar from '../../components/navbar/Navbar';

const Server = () => {
    const [socket, setSocket] = useState(null);
    const [whitelist, setWhitelist] = useState("beta");

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const newSocket = io('https://api.escapefromhunter.com', {
                query: { token },
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Connected to server:', newSocket.id);
                newSocket.emit('getWhitelist');
            });

            newSocket.on('connect_error', () => {
                console.log('Connection error');
                window.location.href = '/login'; 
            });

            newSocket.on('getWhitelist', (type) => {
                setWhitelist(type)
            });

            newSocket.on('disconnect', () => {
                console.log('Disconnected from server');
            });

            return () => {
                newSocket.disconnect(); 
            };
        } else {
            window.location.href = '/login'; 
        }
    }, []);

    const changeWhitelist = (type) => {
        socket.emit('setWhitelisteType', type);
        socket.emit('getWhitelist');
    }
    
    return (
        <div className='server'>
            <Navbar page={'Server'} socket={socket} />
            <div className='server-infos'>
                
                <div className="server-whitelist">

                    <label>Whitelist Gestion</label>
                    <div className="server-whitelist-buttons">
                        <div onClick={() => changeWhitelist('open')} className="whitelist-button" id={whitelist == "open" ? "whitelist-active" : "whitelist-not"}>
                            Open
                        </div>
                        <div onClick={() => changeWhitelist('beta')} className="whitelist-button" id={whitelist == "beta" ? "whitelist-active" : "whitelist-not"}>
                            Beta
                        </div>
                        <div onClick={() => changeWhitelist('close')} className="whitelist-button" id={whitelist == "close" ? "whitelist-active" : "whitelist-not"}>
                            Close
                        </div>
                    </div>

                </div>
    
            </div>
        </div>
    )
}

export default Server