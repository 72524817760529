import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/__index.scss';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";

// Pages 

import Login from './pages/login/Login';
import Main from './pages/main/Main';
import Players from './pages/players/Players';
import Staffs from './pages/staffs/Staffs';
import Bans from './pages/bans/Bans';
import Server from './pages/server/Server';


const checkAuth = () => {
  const token = localStorage.getItem('token');
  console.log('Token:', token);
  return token !== "";
};


const router = createBrowserRouter([
  {
    path: "/",
    element: checkAuth() ? <Main /> : <Navigate to="/login" replace />,
  },
  {
    path: "/players",
    element: checkAuth() ? <Players /> : <Navigate to="/login" replace />,
  },
  {
    path: "/staffs",
    element: checkAuth() ? <Staffs /> : <Navigate to="/login" replace />,
  },
  {
    path: "/bans",
    element: checkAuth() ? <Bans /> : <Navigate to="/login" replace />,
  },
  {
    path: "/server",
    element: checkAuth() ? <Server /> : <Navigate to="/login" replace />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
