
const Ban = ({ban, unban}) => {

    return (
        <div className="ban">
            <div className='ban-top'>
                <label>Ban id : {ban.id}</label>
            </div>
            <div className='ban-line'>
                <i className={"fa-solid fa-id-card"}></i>
                <label>{ban.id}</label>
            </div>
            <div className='ban-line'>
                <i className={"fa-solid fa-note-sticky"}></i>
                <label>{ban.reason}</label>
            </div>
            <div className='ban-line'>
                <i className={"fa-solid fa-database"}></i>
                <label>{ban.license}</label>
            </div>
            <div className='ban-bottom'>
                <div className='ban-button' onClick={unban} >Unban</div>
            </div>
        </div>
    )
}

export default Ban