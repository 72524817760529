

const Staff = ({staff, onClick, deleteStaff}) => {

    const getDate = (date) => {
        const datee = new Date(date)
        const readableDate = datee.toLocaleString('fr-FR', {
            year: 'numeric',
            month: 'long', 
            day: 'numeric',
        })
        return readableDate
    }
    return (
        <div className="staff">
            <div className='staff-top'>
                <label>{staff.username}</label>
            </div>
            <div className='staff-line'>
                <i className={"fa-solid fa-location-dot"}></i>
                <label>{staff._id}</label>
            </div>
            <div className='staff-line'>
                <i className={"fa-solid fa-lock"}></i>
                <label>{staff.isAdmin ? "Full permission" : "Limited permission"}</label>
            </div>
            <div className='staff-line'>
                <i className={"fa-solid fa-calendar-days"}></i>
                <label>
                    {
                        getDate(staff.createdAt)
                    }
                    </label>
            </div>
            <div className='staff-bottom'>
                <div className='staff-button' onClick={deleteStaff} >Delete</div>
                <div className='staff-button' >Password</div>
                <div className='staff-button' onClick={onClick} >Manage</div>
            </div>
        </div>
    )
}

export default Staff