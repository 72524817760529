
import './Staffs.scss'
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Navbar from '../../components/navbar/Navbar';
import Staff from './Staff';
import Input from '../../components/input/Input';

const Staffs = () => {
    const [socket, setSocket] = useState(null);
    const [allStaffs, setAllStaffs] = useState({});
    const [page, setPage] = useState({});
    const [selectedStaff, setSelectedStaff] = useState();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const newSocket = io('https://api.escapefromhunter.com', {
                query: { token },
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Connected to server:', newSocket.id);
                newSocket.emit("needAllStaffs")
            });

            newSocket.on('connect_error', () => {
                console.log('Connection error');
                window.location.href = '/login'; 
            });

            newSocket.on('needAllStaffs', (staffs) => {
                if( selectedStaff ) {
                    console.log(staffs.find(staff => staff.username === selectedStaff.username))
                    setSelectedStaff(staffs.find(staff => staff.username === selectedStaff.username))
                }
                setAllStaffs(staffs)
            });

            newSocket.on('togglePermission', (staff) => {
                setSelectedStaff(staff)
            });


            newSocket.on('disconnect', () => {
                console.log('Disconnected from server');
            });

            return () => {
                newSocket.disconnect(); 
            };
        } else {
            window.location.href = '/login'; 
        }
    }, []);

    const createStaff = () => {
        if (username === "" || password === "") return;
        if (socket) {
            socket.emit("createStaffAccount", username, password); 
            setPage("")
        }
    }

    const deleteStaff = (username) => {
        if (socket) {
            socket.emit("deleteStaff", username); 
            setPage("")
        }
    }

    const togglePermission = async (permission, state) => {
        if (socket) {
            
            socket.emit("togglePermission", selectedStaff.username, permission, state); 
            
        }
    }
    
    
    return (
        <div className='staffs'>
            <Navbar page={'Staffs'} socket={socket} />
            <div className='staff-list'>

                {
                    allStaffs.length > 0 ? allStaffs.map((staff, index) => {
                        return (
                            <>
                                <Staff staff={staff} onClick={async () => setSelectedStaff(staff)} deleteStaff={async () => deleteStaff(staff.username)} />
                            </>
                          
                        );
                    }) : null
                }
                
                <div className='staffs-add' onClick={() => setPage("addStaff")}>
                    Add Staff
                </div>
            </div>

            {
                page === "addStaff" ? 
                
                <div className='add-staff'>
                    <div className='add-modal'>
                        <label>Create new staff account</label>
                        <Input placeholder={"Staff username"} value={username} setValue={async e => setUsername(e)} />
                        <Input placeholder={"Staff password"} value={password} type={"password"} setValue={async e => setPassword(e)} />
                    </div>

                    <span className='create-buttons'>
                        <div className='create-button' onClick={() => setPage("")}>
                            Close
                        </div>
                        <div className='create-button' onClick={() => createStaff()}>
                            Create Staff
                        </div>
                    </span>
                    
                </div>
                
                : null
            }

            {
                selectedStaff ? 
                <>
                    <div className='manage-staff'>
                        
                        <div className='manage-modal'>
                            
                            <div className='manage-content'>
                                <div className='manage-line' onClick={() => togglePermission("isAdmin", !selectedStaff.isAdmin)}>
                                    <label>Admin</label>
                                    {
                                        selectedStaff.isAdmin ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canManagePermission", !selectedStaff.canManagePermission)}>
                                    <label>Manage permission</label>
                                    {
                                        selectedStaff.canManagePermission ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canEnableWhitelist", !selectedStaff.canEnableWhitelist)}>
                                    <label>Enable whitelist</label>
                                    {
                                        selectedStaff.canEnableWhitelist ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canGiveItem", !selectedStaff.canGiveItem)}>
                                    <label>Give item</label>
                                    {
                                        selectedStaff.canGiveItem ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canRestart", !selectedStaff.canRestart)}>
                                    <label>Restart</label>
                                    {
                                        selectedStaff.canRestart ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canWipe", !selectedStaff.canWipe)}>
                                    <label>Wipe</label>
                                    {
                                        selectedStaff.canWipe ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canShowLog", !selectedStaff.canShowLog)}>
                                    <label>Show logs</label>
                                    {
                                        selectedStaff.canShowLog ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                            </div>
                            <div className='manage-content'>
                                <div className='manage-line' onClick={() => togglePermission("canBan", !selectedStaff.canBan)}>
                                    <label>Ban</label>
                                    {
                                        selectedStaff.canBan ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canUnban", !selectedStaff.canUnban)}>
                                    <label>Unban</label>
                                    {
                                        selectedStaff.canUnban ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canKick", !selectedStaff.canKick)}>
                                    <label>Kick</label>
                                    {
                                        selectedStaff.canKick ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canResetStats", !selectedStaff.canResetStats)}>
                                    <label>Reset stats</label>
                                    {
                                        selectedStaff.canResetStats ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canWatch", !selectedStaff.canWatch)}>
                                    <label>Watchscreen</label>
                                    {
                                        selectedStaff.canWatch ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line' onClick={() => togglePermission("canFreezeBunker", !selectedStaff.canFreezeBunker)}>
                                    <label>Freeze bunker</label>
                                    {
                                        selectedStaff.canFreezeBunker ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                                <div className='manage-line'  onClick={() => togglePermission("canViewInventory", !selectedStaff.canViewInventory)}>
                                    <label>View inventory</label>
                                    {
                                        selectedStaff.canViewInventory ? <i className={"fa-solid fa-check"}></i> : <i className={"fa-solid fa-xmark"}></i>
                                    }
                                    
                                </div>
                            </div>
                         </div>

                        <div className='manage-button' onClick={() => {
                            socket.emit("needAllStaffs")
                            setSelectedStaff(null)
                        }}>
                            Close
                        </div>
                    </div>
                </> 
                : null
            }


        </div>
    )
}

export default Staffs