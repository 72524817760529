
import './Main.scss'
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Navbar from '../../components/navbar/Navbar';

const Main = () => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const newSocket = io('https://api.escapefromhunter.com', {
                query: { token },
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Connected to server:', newSocket.id);
            });

            newSocket.on('connect_error', () => {
                console.log('Connection error');
                window.location.href = '/login'; 
            });

            newSocket.on('disconnect', () => {
                console.log('Disconnected from server');
            });

            return () => {
                newSocket.disconnect(); 
            };
        } else {
            window.location.href = '/login'; 
        }
    }, []);
    
    return (
        <div className='main'>
            <Navbar page={'Home'} socket={socket} />
        </div>
    )
}

export default Main