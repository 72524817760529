import "./Button.scss"

const Button = ({text, onClick, icon, selected}) => {
    return (
        <div className="button" id={selected ? 'selected' : null} onClick={onClick}>
            <i className={icon}></i>
            <label>{text}</label>
        </div>
    )
}

export default Button;