
import './Bans.scss'
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Navbar from '../../components/navbar/Navbar';
import Ban from './Ban';
import Input from '../../components/input/Input';

const Bans = () => {
    const [socket, setSocket] = useState(null);
    const [bans, setBans] = useState({});
    const [id, setId] = useState("");
    const [reason, setReason] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const newSocket = io('https://api.escapefromhunter.com', {
                query: { token },
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Connected to server:', newSocket.id);
                newSocket.emit('needBanPlayerList');
            });

            newSocket.on('banPlayerList', (bans) => {
                console.log('Bans:', bans);
                setBans(bans);
            });

            newSocket.on('connect_error', () => {
                console.log('Connection error');
                window.location.href = '/login'; 
            });

            newSocket.on('disconnect', () => {
                console.log('Disconnected from server');
            });

            return () => {
                newSocket.disconnect(); 
            };
        } else {
            window.location.href = '/login'; 
        }
    }, []);

    const unbanPlayer = (id) => {
        if (socket) {
            socket.emit("unbanPlayer", id);
        }
    }

    const banPlayer = () => {
        if (socket) {
            socket.emit("offlineBan", id, reason);
            setShowModal(!showModal);
            setId("")
            setReason("")
        }
    }
    
    return (
        <div className='bans'>
            <Navbar page={'Bans'} socket={socket} />
            <div className='ban-list'>
                {
                    bans.length > 0 && bans !== null ? bans.map((ban, index) => {
                        console.log(ban, index);
                        return (
                            <>
                                <Ban ban={ban} unban={() => unbanPlayer(ban.id)} />
                            </>
                          
                        );
                    }) : null
                }

                <div className='ban-add' onClick={() => setShowModal(!showModal)}>
                    Ban Player
                </div>

                {
                    showModal && 
                    <div className="ban-modal">
                        <div className='ban-mod'>
                            <Input value={id} setValue={e => setId(e)} placeholder={"Enter license or permanent ID "} />
                            <Input value={reason} setValue={e => setReason(e)} placeholder={"Enter the ban reason"} />
                            <div className='ban-buttons'>
                                <div className='ban-add' onClick={() => setShowModal(!showModal)}>
                                    Close
                                </div>
                                <div className='ban-add' onClick={() => banPlayer()}>
                                    Ban player
                                </div>
                            </div>
                        </div>
                    </div>
                }


                
            </div>
        </div>
    )
}

export default Bans