// import React, { useEffect, useRef, useState } from 'react';
// import io from 'socket.io-client';

// const Video = ({player, onClose}) => {
//     const videoRef = useRef(null);
//     const signalingSocket = useRef(null);
//     const peerConnection = useRef(null);

//     useEffect(() => {
//         signalingSocket.current = io('http://46.105.222.102:5000');
//         peerConnection.current = new RTCPeerConnection({
//             iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
//         });

//         const videoElement = videoRef.current;

//         if (peerConnection.current) {
//             peerConnection.current.onicecandidate = (event) => {
//                 if (event.candidate) {
//                     signalingSocket.current.emit('ice-candidate', event.candidate);
//                 }
//             };

//             peerConnection.current.ontrack = (event) => {
//                 const stream = event.streams[0];
//                 if (videoElement) {
//                     videoElement.srcObject = stream;
//                     videoElement.play().catch((error) => {
//                         console.error('Erreur lors de la lecture vidéo:', error);
//                     });
//                 }
//             };
//         }

//         signalingSocket.current.on('offer', async (offer) => {
//             await peerConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
//             const answer = await peerConnection.current.createAnswer();
//             await peerConnection.current.setLocalDescription(answer);
//             signalingSocket.current.emit('answer', {
//                 type: answer.type,
//                 sdp: answer.sdp
//             });
//         });

//         signalingSocket.current.on('ice-candidate', (candidate) => {
//             peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
//         });

//         requestStream();

//         return () => {
//             signalingSocket.current.emit('stop-stream', player);
//             signalingSocket.current.disconnect();
//             peerConnection.current.close();
//         };
//     }, []);

//     const requestStream = () => {
//         console.log(`Demande de flux pour l'émetteur ID: ${player}`);
//         signalingSocket.current.emit('start-stream', player);
//     };

//     const stopStream = () => {
//         signalingSocket.current.emit('stop-stream', player);
//         onClose();
//     };

//     return (
//         <div className="video">
//             <video ref={videoRef} id="player-video" playsInline autoPlay={true} />
//             <button className='video-close' onClick={stopStream}>Close</button>
//         </div>
//     );
// };

// export default Video;



import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

const Video = ({player, onClose}) => {
    const videoRef = useRef(null);
    const signalingSocket = useRef(null);
    const peerConnection = useRef(null);
    let stream = 0;

    useEffect(() => {
        signalingSocket.current = io('https://rtc.escapefromhunter.com');
        peerConnection.current = new RTCPeerConnection({
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        });

        const videoElement = videoRef.current;

        if (peerConnection.current) {
            peerConnection.current.onicecandidate = (event) => {
                if (event.candidate) {
                    signalingSocket.current.emit('ice-candidate', event.candidate, stream);
                }
            };

            peerConnection.current.ontrack = (event) => {
                const stream = event.streams[0];
                if (videoElement) {
                    videoElement.srcObject = stream;
                    videoElement.play().catch((error) => {
                        console.error('Erreur lors de la lecture vidéo:', error);
                    });
                }
            };
        }

        signalingSocket.current.on('offer', async (offer) => {
            await peerConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = await peerConnection.current.createAnswer();
            await peerConnection.current.setLocalDescription(answer);
            signalingSocket.current.emit('answer', {
                type: answer.type,
                sdp: answer.sdp
            }, stream);
        });

        signalingSocket.current.on('stream-started', (streamId) => {
            stream = streamId;
        });

        signalingSocket.current.on('ice-candidate', (candidate) => {
            peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
        });

        requestStream();

        return () => {
            signalingSocket.current.emit('stop-stream', stream);
            signalingSocket.current.disconnect();
            peerConnection.current.close();
        };
    }, []);

    const requestStream = () => {
        console.log(`Demande de flux pour l'émetteur ID: ${player}`);
        signalingSocket.current.emit('start-stream', player);
    };

    const stopStream = () => {
        signalingSocket.current.emit('stop-stream', stream);
        onClose();
    };

    return (
        <div className="video">
            <video ref={videoRef} id="player-video" playsInline autoPlay={true} />
            <button className='video-close' onClick={stopStream}>Close</button>
        </div>
    );
};

export default Video;
