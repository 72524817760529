
import './Players.scss'
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Navbar from '../../components/navbar/Navbar';
import Player from '../../components/player/Player';
import Video from '../../components/video/Video';

const Players = () => {

    const [players, setPlayer] = useState({})
    const [selectedPlayer, setSelectedPlayer] = useState()
    const [socket, setSocket] = useState(null);
    const [page, setPage] = useState("")

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const newSocket = io('https://api.escapefromhunter.com', {
                query: { token },
                reconnection: true
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Connected to server:', newSocket.id);
                newSocket.emit("needPlayers");
            });

            newSocket.on('needPlayers', (players) => {
                console.log(players);
                setPlayer(players);
            });

            newSocket.on('connect_error', () => {
                console.log('Connection error');
                window.location.href = '/login'; 
            });

            newSocket.on('disconnect', () => {
                console.log('Disconnected from server');
            });

            return () => {
                newSocket.disconnect(); 
            };
        } else {
            window.location.href = '/login'; 
        }
    }, []);

    const kickPlayer = (playerSource, reason) => {
        if (socket) {
            socket.emit("kickPlayer", playerSource, reason); 
            console.log(`Kicked player: ${playerSource}`);
        }
    };

    const banPlayer = (playerSource, reason) => {
        if (socket) {
            socket.emit("banPlayer", playerSource, reason); 
            console.log(`ban player: ${playerSource}`);
        }
    };
    
    return (
        <div className='players'>
            <Navbar page={'Players'} socket={socket} />
            <div className='player-list'>
                {
                    players.length > 0 ? players.map( player => {
                        console.log(player);
                        return (
                            <>
                                <Player key={player.source} player={player} onClick={async () => setSelectedPlayer(player)} ban={async (reason) => banPlayer(player.source, reason)} kick={async (reason) => kickPlayer(player.source, reason)} />
                            </>
                          
                        );
                    }) : null
                }
                
            </div>
            {
                selectedPlayer ? 
                <div className='player-video'>
                    <Video player={selectedPlayer.id} onClose={async () => setSelectedPlayer()}/>
                    {/* <div className='video-gestion'>
                        <label className='gestion-title'>Gestion {selectedPlayer.Account.Pseudo}</label>
                        <label className='gestion-sub'>Quick actions</label>
                        <div className='gestion-quick'>
                            <div className='player-button' onClick={() => setPage("ban")}>Ban</div>
                            <div className='player-button' onClick={() => setPage("kick")}>Kick</div>
                            <div className='player-button' onClick={() => setPage("wipe")}>Wipe</div>
                            <div className='player-button'>Inventory</div>
                        </div>
                        <div className='gestion-quick'>
                            <div className='player-button' onClick={() => setPage("ban")}>Identifiers</div>
                            <div className='player-button' onClick={() => setPage("kick")}>Kick</div>
                            <div className='player-button' onClick={() => setPage("wipe")}>Wipe</div>
                            <div className='player-button'>Inventory</div>
                        </div>

                    </div> */}
                </div>
                : null
            }
        </div>
    )
}

export default Players