import { useState } from 'react'
import './Player.scss'
import Input from '../input/Input'

const Player = ({player, onClick, kick, ban}) => {

    const [page, setPage] = useState("")
    const [value, setValue] = useState("")

    return (
        <div className='player'>
            {
                page === "" ? 
                <>
                    <div className='player-top'>
                        <div className='player-button' onClick={onClick}>{player.pseudo}</div>
                        <label>{player.id}</label>
                    </div>
                    <div className='player-line'>
                        <i className={"fa-solid fa-location-dot"}></i>
                        <label>{player.position}</label>
                    </div>
                    <div className='player-line'>
                        <i className={"fa-solid fa-money-bill"}></i>
                        <label>{player.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " $"}</label>
                    </div>

                    <div className='player-bottom'>
                        <div className='player-button' onClick={() => setPage("ban")}>Ban</div>
                        <div className='player-button' onClick={() => setPage("kick")}>Kick</div>
                        <div className='player-button' onClick={() => setPage("wipe")}>Wipe</div>
                        <div className='player-button' onClick={onClick}>Watch</div>
                    </div>
                </>
                : page === "kick" ?
                <>
                    <Input placeholder={"Kick reason."} value={value} setValue={setValue} type={"text"} />
                    <div className='player-bottom'>
                        <div className='player-button' onClick={() => setPage("")}>Cancel</div>
                        <div className='player-button' onClick={() => kick(value)}>Kick</div>
                    </div>
                </>
                : page === "wipe" ?
                <>
                    <label>Are you sure to wipe {player.pseudo}</label>
                    <div className='player-bottom'>
                        <div className='player-button' onClick={() => setPage("")}>Cancel</div>
                        <div className='player-button' onClick={() => setPage("wipe")}>Wipe</div>
                    </div>
                </>
                : page === "ban" ?
                <>
                    <Input placeholder={"Ban reason."} value={value} setValue={setValue} type={"text"} />
                    <div className='player-bottom'>
                        <div className='player-button' onClick={() => setPage("")}>Cancel</div>
                        <div className='player-button' onClick={() => ban(value)}>Ban</div>
                    </div>
                </>
                :
                null
            }
            
            
        </div>
    )

}

export default Player