import { useNavigate } from 'react-router-dom';
import Button from './Button';
import './Navbar.scss'

const Navbar = ({page, socket}) => {  
    const navigate = useNavigate();

    const requireStaff = () => {
        if (socket !== null) {
            socket.emit("staffPermission")

            socket.on("staffPermission", (permission) => {
                if (!permission) {
                    window.location.href = '/';
                } else {
                    window.location.href = '/staffs'
                }
            })
        }
    }

    const requireServer = () => {
        if (socket !== null) {
            socket.emit("serverPermission")

            socket.on("serverPermission", (permission) => {
                if (!permission) {
                    window.location.href = '/';
                } else {
                    window.location.href = '/server'
                }
            })
        }
    }


    return (
        <nav className='navbar'>

            <img className='navbar-logo' src='https://i.ibb.co/sF8TbN8/logo512.png' />
            <Button text={'Home'} onClick={() => window.location.href = '/'} icon={'fa-solid fa-home'} selected={page == 'Home'} />
            <Button text={'Players'} onClick={() => window.location.href = '/players'} icon={'fa-solid fa-user'} selected={page == 'Players'} />
            <Button text={'Bans'} onClick={() => window.location.href = '/bans'} icon={'fa-solid fa-ban'} selected={page == 'Bans'}  />
            <Button text={'Staffs'} icon={'fa-solid fa-lock'} selected={page == 'Staffs'} onClick={requireStaff} />
            <Button text={'Server'} icon={'fa-solid fa-server'} selected={page == 'Server'} onClick={requireServer} />

            <Button text={'Log out'} icon={'fa-solid fa-right-from-bracket'} />
        </nav>
    )

}

export default Navbar;